<template>
    <v-container fluid>
        <div class="main">
            <div class="d-flex justify-space-between">
                <v-btn color="gifty" depressed exact text to="/occasion_cards">
                    <v-icon right class="mr-3">mdi-arrow-left</v-icon>
                    Retour
                </v-btn>
                <h3 class="mb-4">Catégories Cartes d'occasions</h3>
            </div>

            <div class="d-flex justify-space-between mb-5">

                <div></div>
                <v-btn class="white--text ml-4" color="gifty" depressed exact @click="addCategory()">
                    <v-icon right class="mr-2">mdi-plus</v-icon>
                    Nouvelle catégorie
                </v-btn>
            </div>


            <v-row v-if="loading" class="mt-4">

                <v-col cols="4" sm="12" md="3" lg="3" v-for="index in (1, 8)" :key="index">
                    <v-skeleton-loader type="card" />
                </v-col>

            </v-row>

            <v-row v-else>

                <v-col cols="12">

                    <v-card class="rounded-lg shadow" v-if="occasion_categories.data.length > 0">
                        <v-simple-table elevation="0">
                            <template v-slot:default>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nom</th>
                                        <th>Nom Ar</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="item in occasion_categories.data" :key="item.id">
                                        <td>
                                            {{ item.id }}
                                        </td>
                                        <td>
                                            {{ item.name }}
                                        </td>
                                        <td>
                                            {{ item.name_ar }}
                                        </td>
                                        <td>
                                            <v-menu transition="slide-x-transition" bottom right>
                                                <v-list dense>
                                                    <v-list-item @click="updateCategory(item)">
                                                        <v-list-item-icon>
                                                            <v-icon color="black">mdi-pencil</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Modifier</v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item @click="deleteCategory(item)">
                                                        <v-list-item-icon>
                                                            <v-icon color="red">mdi-delete</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>Supprimer</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn color="black" icon dark v-bind="attrs" v-on="on">
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>

                    <v-card class="rounded-lg shadow" v-if="occasion_categories.data.length == 0">

                        <v-card-text>
                            <div class="text-center">
                                <v-avatar tile size="200">
                                    <v-img :src="require('@/assets/database.svg')"></v-img>
                                </v-avatar>
                                <p>Aucun enregistrement</p>
                            </div>

                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col cols="12">
                    <div v-if="occasion_categories.data && occasion_categories.data.length > 0"
                        class="mt-1 d-flex justify-space-between">
                        <div :style="{ width: '80px' }">
                            <v-select outlined v-model="filter.size" dense :items="[5, 10, 20, 30, 40, 50]"
                                @change="fetchData"></v-select>
                        </div>

                        <v-pagination total-visible="6" v-if="occasion_categories.total > filter.size" circle
                            v-model="filter.page" :length="Math.ceil(occasion_categories.total / filter.size)"
                            @input="fetchData" hide-details></v-pagination>
                    </div>
                </v-col>
            </v-row>
            <ConfirmDelete ref="deleteCategory" @refresh="fetchData" />
            <FormCategory ref="formCategory" @refresh="fetchData()" />
        </div>
    </v-container>
</template>

<script>
import { HTTP } from '@/http-common';
import ConfirmDelete from './ConfirmDelete.vue';
import FormCategory from './FormCategory.vue';


export default {
    components: { ConfirmDelete, FormCategory },
    data() {
        return {
            occasion_categories: {},
            loading: true,
            filter: {
                page: 1,
                size: 10
            }
        }
    },
    methods: {
        fetchData() {
            this.loading = true;
            HTTP.get("v1/occasion_categories", {
                params: {
                    ...this.filter
                }
            }).then((res) => {
                this.occasion_categories = res.data.data;
                this.loading = false;

            }).catch((e) => {
                this.loading = false;
                console.log(e);
            });
        },


        addCategory() {
            this.$refs.formCategory.open();
        },
        updateCategory(item) {
            this.$refs.formCategory.open(item);
        },
        deleteCategory(item) {
            this.$refs.deleteCategory.open(item);
        }

    },
    mounted() {
        this.fetchData();
    }
}
</script>

<style scoped></style>