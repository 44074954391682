<template>
    <div>
        <v-dialog v-model="dialog" max-width="600" persistent>
            <v-card>
                <v-card-title>
                    {{ item ? "Modifier une catégorie" : "Nouvelle catégorie" }}
                    <v-spacer />
                    <v-btn icon class="v-btn--active" color="primary" @click="close()">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="pa-6" style="overflow-y: scroll; max-height: 450px">
                    
                    <v-text-field
                      label="Nom *"
                      outlined
                      @input="
                        formErrors && formErrors.name
                          ? (formErrors.name = '')
                          : false
                      "
                      :error-messages="
                        formErrors && formErrors.name ? formErrors.name[0] : ''
                      "
                      v-model="form.name"
                    />

                    <v-text-field
                      label="Nom arabe *"
                      outlined
                      @input="
                        formErrors && formErrors.name_ar
                          ? (formErrors.name_ar = '')
                          : false
                      "
                      :error-messages="
                        formErrors && formErrors.name_ar ? formErrors.name_ar[0] : ''
                      "
                      v-model="form.name_ar"
                    />

                    

                    <v-btn depressed :loading="btnLoading" large block color="primary" class="rounded-lg mt-4"
                        @click="save">
                        Enregister
                    </v-btn>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { HTTP } from '@/http-common';
export default {
    data() {
        return {
            dialog: false,
            item: null,
            btnLoading: false,
            form: {
                name: '',
                name_ar: ''

            },
            formErrors: {},
        }
    },
    methods: {
        open(item) {
            this.item = item;
            if(item){
                this.form = {
                    name: item.name,
                    name_ar: item.name_ar,
                }
            }else{
                this.form = {
                    name: "",
                    name_ar: "",
                }
            }

            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        save() {
            this.formErrors = {};
            this.btnLoading = true;
            var formData = new FormData();
            Object.keys(this.form).forEach((key) => {
                formData.append(key, this.form[key]);
            });
            var url = '/v1/occasion_categories';
            if(this.item){
                formData.append("_method", "PUT");
                url+= "/"+this.item.id;
            }

            HTTP.post(url, formData).then((res) => {
                this.btnLoading = false;
                this.$successMessage = "Enregistrement avec succès"
                this.$emit("refresh");
                this.close();
            }).catch((err) => {
                this.btnLoading = false;

                if (err.response && err.response.data && err.response.data.errors) {
                    this.formErrors = err.response.data.errors;
                } else {
                    this.$errorMessage = "Une erreur s'est produite";
                }
                console.log(err);
            })
        }
    },
    mounted() {

    }

}
</script>

<style lang="scss" scoped></style>